import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import { XCircle, CloudUpload } from "react-bootstrap-icons";
import Select from "react-select";
import { Popover, Tag } from "antd";

import FeatherIcon from "feather-icons-react";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import img from "../img/info.png";
import ReactQuill from "react-quill";
import "../table.css";
import ModalCat from "../ModalCat";
import ModalSubCat from "../ModalSubCat";
import "../AddProductForm.css";
import PageTitle from "../../../../components/PageTitle";
import FileUploader from "../../../../components/FileUploader";
import { useNavigate, useParams } from "react-router-dom";

function UpdateProduct() {
  const [price, setPrice] = useState("");
  const [files, setFiles] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [discount, setDiscount] = useState("");
  const [priceAfterDiscount, setPriceAfterDiscount] = useState("");
  const [catdata, setCatData] = useState([]);
  const [subcatdata, setsubCatData] = useState([]);
  const [brand, setBrand] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [subcategoryNames, setSubcategoryName] = useState("");
  const [tags, setTags] = useState([]);
  const [input, setInput] = useState("");
  const [productcode, setProductsCode] = useState("");
  const [showCenteredModal, setShowCenteredModal] = useState(false);
  const [showCenteredModalsub, setShowCenteredModalsub] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const TenantId = localStorage.getItem("TenantId");
  const [show, setShow] = useState(false);
  const [showsub, setShowsub] = useState(false);
  const [showVariant, setShowvariant] = useState(false);
  const [showAddInfo, setShowAddInfo] = useState(false);
  const [catidpost, setCatIdPost] = useState("");
  const [subcatidpost, setSubCatIdPost] = useState("");
  const [brandidpost, setbrandIdPost] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [NewProduct, setNewProduct] = useState(true);
  const [discountEndTime, setDiscountEndTime] = useState("");
  const [qty, setQty] = useState(0);
  const handlesubopen = () => setShowsub(true);
  const handlesubclose = () => setShowsub(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [infoPairs, setInfoPairs] = useState([{ key: "", value: "" }]);
  const navigate = useNavigate();
  const catoption = catdata.map((category) => ({
    value: category.categoryId,
    label: category.categoryName,
  }));
  const subcatoption = subcatdata.map((subcategory) => ({
    value: subcategory.subcategoryId,
    label: subcategory.subcategoryName,
  }));
  const branddatum = brand.map((brand) => ({
    value: brand.brandId,
    label: brand.brandName,
  }));



  
  const { id } = useParams();
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_FOS_CLIENT_API
          }/products/${id}?tenantName=${localStorage.getItem("TenantId")}`
        );
        const data = await response.json();
      
        // Initialize state with fetched data
        setName(data.name);
        setDescription(data.description);
        setPrice(data.price);
        setDiscount(data.discount);
        setPriceAfterDiscount(data.priceAfterDiscount);
        setCatIdPost(data.categoryId);
        setbrandIdPost(data.brandId);
        setTags(data.tag);
        setDiscountEndTime(data.discountEndTime);
        setQty(data.qty);
        setSubCatIdPost(data.subcategoryId);
        setNewProduct(data.NewProduct);
        setProductsCode(data.productCode);
        setFiles1(data.imageUrl)
        if (data.AdditionalInfo && Array.isArray(data.AdditionalInfo)) {
          setInfoPairs(data.AdditionalInfo);
        }
        // Set other states as needed
      } catch (error) {
        console.error("Error fetching product:", error);
        toast.error("Error fetching product data");
      }
    };

    fetchProduct();
  }, [id]);



  //* Handle Varient start

  const [variants, setVariants] = useState([
    {
      variantName: "",
      details: [{ name: "", price: "", available: "", image: "" }],
    },
  ]);

  const addVariant = () => {
    setVariants([
      ...variants,
      {
        variantName: "",
        details: [{ name: "", price: "", available: "", image: "" }],
      },
    ]);
  };
  const handleDescriptionChange = (value) => {
    setDescription(value);
  };
  const addSubVariant = (index) => {
    const newVariants = [...variants];
    newVariants[index].details.push({
      name: "",
      price: "",
      available: "",
      image: "",
    });
    setVariants(newVariants);
  };

  const removeSubVariant = (variantIndex, subVariantIndex) => {
    const newVariants = [...variants];
    newVariants[variantIndex].details.splice(subVariantIndex, 1);
    setVariants(newVariants);
  };

  const removeVariant = (variantIndex) => {
    const newVariants = [...variants];
    newVariants.splice(variantIndex, 1);
    setVariants(newVariants);
  };

  const handleVariantChange = (variantIndex, subVariantIndex, field, value) => {
    const newVariants = [...variants];
    if (subVariantIndex !== null) {
      newVariants[variantIndex].details[subVariantIndex][field] = value;
    } else {
      newVariants[variantIndex][field] = value;
    }
    setVariants(newVariants);
  };

  const handleImageUpload = (variantIndex, subVariantIndex, file) => {
    const newVariants = [...variants];
    newVariants[variantIndex].details[subVariantIndex].image =
      URL.createObjectURL(file);
    setVariants(newVariants);
  };

  const handleVarientSubmit = (e) => {
    e.preventDefault();
    // console.log("-->", variants[0].details[0].image);

    setShowvariant(false);
  };

  //* Handle Variend ends

  //* Handle Additional Info starts



  const addInfoPair = () => {
    setInfoPairs([...infoPairs, { key: "", value: "" }]);
  };

  const removeInfoPair = (index) => {
    if (infoPairs.length > 1) {
      const updatedPairs = infoPairs.filter((_, i) => i !== index);
      setInfoPairs(updatedPairs);
    }
  };

  const handleInfoInputChange = (index, field, value) => {
    const updatedPairs = [...infoPairs];
    updatedPairs[index][field] = value;
    setInfoPairs(updatedPairs);
  };

  const handleAdditionalInfoSubmit = (e) => {
    e.preventDefault();

    setShowAddInfo(false);
  };

  const handleImageUploadpost = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/upload`,
        {
          method: "POST",
          body: formData
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload images");
      }

      const result = await response.json();
      return result.urls; // Array of uploaded image URLs
    } catch (error) {
      toast.error("Image upload failed");
      throw error;
    }
  };


  const handleSubmit = async () => {
    try {
      // Upload images first
      const newImageUrls = await handleImageUploadpost(files);
      const allImageUrls = [...files1, ...newImageUrls];
      // Prepare the product data
      const productData = {
        name: name,                           // Product name
        description: description,             // Product description
        price: parseInt(price, 10),           // Product price
        categoryId: catidpost,                // Category ID
        subcategoryId: subcatidpost,          // Subcategory ID
        brandId: brandidpost,                 // Brand ID
        NewProduct: NewProduct,               // Boolean flag for new product
        productCode: productcode,             // Product code
        priceAfterDiscount: parseInt(priceAfterDiscount),  // Price after discount
        imageUrl: allImageUrls,               // Array of image URLs
        discount: parseInt(discount, 10),     // Discount value
        discountEndTime: discountEndTime,     // Discount end time
        tag: tags,                            // Tags for the product
        qty: parseInt(qty, 10),               // Quantity available
        AdditionalInfo: infoPairs             // Array of additional info key-value pairs
      };
      

      // Submit product data
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/products/${id}?tenantName=${TenantId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(productData),
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to create product: ${response.statusText}`);
      }

      const result = await response.json();
      toast.success("Product created successfully!");
      navigate(`/apps/stores`);
      // Clear the form inputs
      setName("");
      setDescription("");
      setPrice("");
      setDiscount("");
      setPriceAfterDiscount("");
      setCatIdPost("");
      setSubCatIdPost("");
      setbrandIdPost("");
      setTags([]);
      setDiscountEndTime("");
      setQty(0);
      setFiles([]);
      setVariants([
        {
          variantName: "",
          details: [{ name: "", price: "", available: "", image: "" }],
        },
      ]);
      setInfoPairs([{ key: "", value: "" }]);
    } catch (error) {
      toast.error("Error creating product");
      console.error("Error:", error);
    }
  };

  // ===============================================================================get====================================================
  // Handle category submission
  const refreshCategories = () => {
    fetch(
      `${process.env.REACT_APP_FOS_CLIENT_API}/category?tenantName=${TenantId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setCatData(data); // Update the state with the fetched categories
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        toast.error(`Error fetching categories: ${error.message}`);
      });
  };

  // Function to refresh subcategories
  const refreshSubcategories = () => {
    fetch(
      `${process.env.REACT_APP_FOS_CLIENT_API}/subcategory?tenantName=${TenantId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setsubCatData(data); // Update the state with the fetched subcategories
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
        toast.error(`Error fetching subcategories: ${error.message}`);
      });
  };
  const refreshbrands = () => {
    fetch(
      `${process.env.REACT_APP_FOS_CLIENT_API}/brands?tenantName=${TenantId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setBrand(data); // Update the state with the fetched subcategories
      })
      .catch((error) => {
        console.error("Error fetching subcategories:", error);
        toast.error(`Error fetching subcategories: ${error.message}`);
      });
  };

  // Handle category submission
  const handleCategorySubmit = (e) => {
    e.preventDefault();

    // Check if category already exists
    const isCategoryExists = catdata.some(
      (cat) => cat.categoryName.toLowerCase() === categoryName.toLowerCase()
    );

    if (isCategoryExists) {
      toast.warning("Category already exists"); // Show warning if category already exists
      return;
    }

    // Proceed with the POST request if the category does not exist
    fetch(
      `${process.env.REACT_APP_FOS_CLIENT_API}/category/${TenantId}/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ categoryName: categoryName }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setShowCenteredModal(false);
        toast.success("Category added successfully!");
        setCategoryName(""); // Clear category name
        refreshCategories(); // Refresh categories after adding a new one
      })
      .catch((error) => {
        console.error("Error adding category:", error);
        toast.error(`Error Creating category: ${error.message}`);
      });
  };

  // Handle subcategory submission
  const handleSubcategorySubmit = (e) => {
    e.preventDefault();

    // Check if category already exists
    const isCategoryExists = catdata?.some(
      (cat) =>
        cat?.subcategoryName?.toLowerCase() === subcategoryNames?.toLowerCase()
    );

    if (isCategoryExists) {
      toast.warning("Category already exists"); // Show warning if category already exists
    } else {
      fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/subcategory?tenantName=${TenantId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subcategoryName: subcategoryNames,
            categoryId: selectedCategory,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setShowCenteredModalsub(false);
          toast.success("Subcategory added successfully!");
          refreshSubcategories(); // Refresh subcategories after adding a new one
        })
        .catch((error) => {
          console.error("Error adding subcategory:", error);
          toast.error(`Error Creating subcategory: ${error.message}`);
        });
    }
  };
  const handleBrandSubmit = (e) => {
    e.preventDefault();

    // Check if category already exists
    const isCategoryExists = brand?.some(
      (cat) => cat?.brandName?.toLowerCase() === subcategoryNames?.toLowerCase()
    );

    if (isCategoryExists) {
      toast.warning("Category already exists"); // Show warning if category already exists
    } else {
      fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/subcategory?tenantName=${TenantId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subcategoryName: subcategoryNames,
            categoryId: selectedCategory,
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setShowCenteredModalsub(false);
          toast.success("Subcategory added successfully!");
          refreshSubcategories(); // Refresh subcategories after adding a new one
        })
        .catch((error) => {
          console.error("Error adding subcategory:", error);
          toast.error(`Error Creating subcategory: ${error.message}`);
        });
    }
  };

  // Fetch categories and subcategories on component mount
  useEffect(() => {
    refreshCategories(); // Fetch categories
    refreshSubcategories();
    refreshbrands(); // Fetch subcategories
  }, []);

  // ================================================DiscountCalculate=========================================================

  const calculateDiscount = (price, discount) => {
    if (price && discount) {
      const priceValue = parseFloat(price);
      const discountValue = parseFloat(discount);
      const discountedPrice = priceValue - (priceValue * discountValue) / 100;
      return discountedPrice.toFixed(2);
    }
    return "";
  };

  const handlePriceChange = (e) => {
    const newPrice = e.target.value;
    setPrice(newPrice);
    setPriceAfterDiscount(calculateDiscount(newPrice, discount));
  };

  const handleDiscountChange = (e) => {
    const newDiscount = e.target.value;
    setDiscount(newDiscount);
    setPriceAfterDiscount(calculateDiscount(price, newDiscount));
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  // ===================================================================Tag==========================================================================
  const handleAddTag = () => {
    if (input && !tags.includes(input)) {
      setTags([...tags, input]);
      setInput("");
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  // ============================================================pophover content=========================================================

  {
    /* Same as */
  }

  const catcontent = (
    <div style={{ cursor: "pointer" }}>
      <div onClick={() => setShowCenteredModal(true)}>
        {/* <img className="w-25" src={img} alt="" /> */}
        <i style={{ cursor: "pointer" }}>
          <FeatherIcon
            icon="plus-circle"
            className="text-success"
            style={{ width: "18px", height: "18px" }}
          />
        </i>
        <span style={{ marginLeft: 8, fontSize: "13px" }}>Add Category</span>
      </div>

      <div onClick={() => handleShow()} className="mt-1">
        <i style={{ cursor: "pointer" }}>
          <FeatherIcon
            icon="alert-circle"
            style={{ width: "18px", height: "18px" }}
          />
        </i>
        <span style={{ marginLeft: 8, fontSize: "13px" }}>
          <span className="text-dark">Edit & Delete</span>
        </span>
      </div>
    </div>
  );
  const subcatcontent = (
    <div style={{ cursor: "pointer" }}>
      <div onClick={() => setShowCenteredModalsub(true)}>
        {/* <img className="w-25" src={img} alt="" /> */}
        <i style={{ cursor: "pointer" }}>
          <FeatherIcon
            icon="plus-circle"
            className="text-success"
            style={{ width: "18px", height: "18px" }}
          />
        </i>
        <span style={{ marginLeft: 8, fontSize: "13px" }}>Add Category</span>
      </div>

      <div onClick={() => handlesubopen()} className="mt-1">
        <i style={{ cursor: "pointer" }}>
          <FeatherIcon
            icon="alert-circle"
            style={{ width: "18px", height: "18px" }}
          />
        </i>
        <span style={{ marginLeft: 8, fontSize: "13px" }}>
          <span className="text-dark">Edit & Delete</span>
        </span>
      </div>
    </div>
  );
  const brandcontent = (
    <div style={{ cursor: "pointer" }}>
      <img className="w-25" src={img} alt="" />
      <span
        onClick={() => setShowCenteredModal(true)}
        style={{ marginLeft: 8 }}
      >
        Add
      </span>
    </div>
  );

  // ===================================================ReactQuill=======================================================
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ color: [] }, { background: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "color",
    "background",
    "link",
    "image",
    "video",
  ];

  const quillStyles = {
    height: "200px",
    width: "100%",
  };

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "Form", path: "/forms/product/add" },
          { label: "Product Add", path: "/forms/product/add", active: true },
        ]}
        title={"Product Add"}
      />

      <Card>
        <Card.Body>
          <Row>
            <Col md={6}>
              <div className="container">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label>Product Name</Form.Label>
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    name="name"
                    placeholder="Enter product name"
                  />
                </Form.Group>
              </div>
            </Col>
            <Col md={6}>
              <div className="container">
                <Form.Group as={Row} className="mb-3">
                  <Form.Label>Product Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="id"
                    value={productcode}
                    onChange={(e) => setProductsCode(e.target.value)}
                    placeholder="Enter product id (optional)"
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
        <FileUploader
            setSelectedFiles={setFiles}
            selectedFiles={files}
            files1={files1}
            setFiles1={setFiles1} // Pass setter for files1
            onFileUpload={(files) => setFiles(files)}
          />
        </Card.Body>
      </Card>

      <Row>
        <Col md={6}>
          <Card>
            <Card.Header className="bg-white">Prices</Card.Header>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="price">Price</Form.Label>
                <Form.Control
                  type="text"
                  id="price"
                  name="price"
                  placeholder="Enter price"
                  value={price}
                  onChange={handlePriceChange}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="discount">Discount</Form.Label>
                <Form.Control
                  type="text"
                  id="discount"
                  name="discount"
                  placeholder="Enter discount %"
                  value={discount}
                  onChange={handleDiscountChange}
                />
              </Form.Group>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="priceAfterDiscount">
                  Price After Discount
                </Form.Label>
                <Form.Control
                  type="text"
                  id="priceAfterDiscount"
                  name="priceAfterDiscount"
                  placeholder="After Discount"
                  value={priceAfterDiscount}
                  readOnly
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header className="bg-white">Details</Card.Header>
            <Card.Body className="p-3">
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <div className="d-flex align-items-center">
                  <Select
                    className="flex-grow-1"
                    options={catoption}
                    value={catoption.find(
                      (option) => option.value === catidpost
                    )}
                    onChange={(selectedOption) =>
                      setCatIdPost(selectedOption.value)
                    }
                    placeholder="Select an option..."
                    isSearchable
                  />
                  <Popover
                    placement="left"
                    content={catcontent}
                    title={null}
                    trigger="click"
                  >
                    <i style={{ cursor: "pointer" }} className="ms-2">
                      <button className="  btn btn-sm btn-outline-dark">
                        <FeatherIcon icon="plus" />
                      </button>
                    </i>
                  </Popover>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Subcategory</Form.Label>
                <div className="d-flex align-items-center">
                  <Select
                    className="flex-grow-1"
                    options={subcatoption}
                    placeholder="Select an option..."
                    isSearchable
                    value={subcatoption.find(
                      (option) => option.value === subcatidpost
                    )}
                    onChange={(selectedOption) =>
                      setSubCatIdPost(selectedOption.value)
                    }
                  />
                  <Popover
                    placement="left"
                    content={subcatcontent}
                    title={null}
                    trigger="click"
                  >
                    <i style={{ cursor: "pointer" }} className="ms-2">
                      <button className=" btn btn-sm btn-outline-dark">
                        <FeatherIcon icon="plus" />
                      </button>
                    </i>
                  </Popover>
                </div>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Brand</Form.Label>
                <div className="d-flex align-items-center">
                  <Select
                    className="flex-grow-1"
                    options={branddatum}
                    placeholder="Select an option..."
                    isSearchable
                    value={branddatum.find(
                      (option) => option.value === brandidpost
                    )}
                    onChange={(selectedOption) =>
                      setbrandIdPost(selectedOption.value)
                    }
                  />
                  <Popover
                    placement="left"
                    content={brandcontent}
                    title={null}
                    trigger="click"
                  >
                    <i style={{ cursor: "pointer" }} className="ms-2">
                      <button className=" btn btn-sm btn-outline-dark">
                        <FeatherIcon icon="plus" />
                      </button>
                    </i>
                  </Popover>
                </div>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card className="p-2">
        <Card.Body>
          <Form.Group className="mb-1">
            <Form.Label>Product Quantity</Form.Label>
            <Form.Control
              value={qty}
              onChange={(e) => setQty(e.target.value)}
              type="text"
              name="qunatity"
              placeholder="Enter product Quantity"
            />
          </Form.Group>
        </Card.Body>

        <Row>
          <Col md={6}>
            <div className="container">
              <Form.Group className="mb-3">
                <Form.Label>Discount EndDate</Form.Label>
                <Form.Control
                  type="date"
                  value={discountEndTime}
                  onChange={(e) => setDiscountEndTime(e.target.value)}
                  name="name"
                  placeholder="Enter date"
                />
              </Form.Group>
            </div>
          </Col>
          <Col md={3}>
            <div className="container">
              <Form.Group className="mb-3">
                {infoPairs[0].key == "" ? (
                  <Form.Label>Add Info (optional)</Form.Label>
                ) : (
                  <Form.Label>Edit Info</Form.Label>
                )}
                <div>
                  {infoPairs[0].key == "" ? (
                    <button
                      onClick={() => setShowAddInfo(true)}
                      className="btn btn-soft-primary"
                    >
                      <FeatherIcon
                        icon="plus-circle"
                        style={{ width: "18px", height: "18px" }}
                      />{" "}
                      Add Additional Info
                    </button>
                  ) : (
                    <button
                      onClick={() => setShowAddInfo(true)}
                      className="btn btn-soft-primary"
                    >
                      <FeatherIcon
                        icon="plus-circle"
                        style={{ width: "18px", height: "18px" }}
                      />{" "}
                      Edit Additional Info
                    </button>
                  )}
                </div>
              </Form.Group>
            </div>
          </Col>
          <Col md={3}>
            <div className="container">
              <Form.Group className="mb-3">
                {variants[0].variantName == "" ? (
                  <Form.Label>Add Variant (optional)</Form.Label>
                ) : (
                  <Form.Label>Edit Variant</Form.Label>
                )}
                <div>
                  {variants[0].variantName == "" ? (
                    <button
                      onClick={() => setShowvariant(true)}
                      className="btn btn-soft-primary"
                    >
                      <FeatherIcon
                        icon="plus-circle"
                        style={{ width: "18px", height: "18px" }}
                      />{" "}
                      Add Variant
                    </button>
                  ) : (
                    <button
                      onClick={() => setShowvariant(true)}
                      className="btn btn-soft-primary"
                    >
                      <FeatherIcon
                        icon="plus-circle"
                        style={{ width: "18px", height: "18px" }}
                      />{" "}
                      Edit Variant
                    </button>
                  )}
                </div>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Card>
      <Card className="p-2 h-100">
        <Card.Body>
          <Form.Group className="mb-2">
            <Form.Label>Tags</Form.Label>

            <div className="row">
              <div className="col-6">
                <Form.Control
                  type="text"
                  value={input}
                  onChange={handleInputChange}
                  placeholder="Enter product quantity"
                />
              </div>
              <div className="col-2">
                <button className="btn btn-soft-primary" onClick={handleAddTag}>
                  Add Tags
                </button>
              </div>
              <div className="col-2">
                <div className="container">
                  <Form.Group className="mb-3">
                    <Form.Label>New Product</Form.Label>
                    <Form.Check
                      type="checkbox"
                      value={NewProduct}
                      onChange={(e) => setNewProduct(e.target.checked)}
                      name="id"
                      placeholder="Enter product id (optional)"
                      label="(optional)"
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </Form.Group>
          <div className="mt-3">
            {tags.length > 0 && (
              <div>
                <h5>Added Tag:</h5>
                {tags.map((tag, index) => (
                  <button
                    className="mr-2 mb-2 btn btn-soft-danger rounded-pill ms-1"
                    onClick={() => handleRemoveTag(tag)}
                    style={{ cursor: "pointer" }}
                  >
                    {tag}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="mb-4 mt-2 h-100">
            <Form.Label>Description</Form.Label>
            <ReactQuill
              modules={modules}
              formats={formats}
              style={quillStyles}
              value={description}
              onChange={handleDescriptionChange}
              required
            />
          </div>

          <Button onClick={handleSubmit} type="button" className="mt-3">
            Submit
          </Button>
        </Card.Body>
      </Card>

      {/* =================================================================MODAL============================================================ */}
      <Modal
        show={showCenteredModal}
        onHide={() => setShowCenteredModal(false)}
        centered
      >
        <Modal.Header onHide={() => setShowCenteredModal(false)} closeButton>
          <Modal.Title as="h5">Add Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  type="text"
                  name="name"
                  placeholder="Enter CategotyName"
                />
              </Form.Group>
              <Button
                onClick={handleCategorySubmit}
                type="button"
                className="btn btn-primary"
              >
                Add
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showCenteredModalsub}
        onHide={() => setShowCenteredModalsub(false)}
        centered
      >
        <Modal.Header onHide={() => setShowCenteredModalsub(false)} closeButton>
          <Modal.Title as="h5">Add Subcategory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>SubCategoryName</Form.Label>
                <Form.Control
                  value={subcategoryNames}
                  onChange={(e) => setSubcategoryName(e.target.value)}
                  type="text"
                  name="name"
                  placeholder="Enter SubCategotyName"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>CategoryName</Form.Label>
                <Select
                  className="flex-grow-1"
                  options={catoption}
                  placeholder="Select an Category..."
                  isSearchable
                  value={catoption.find(
                    (option) => option.value === selectedCategory
                  )}
                  onChange={(selectedOption) =>
                    setSelectedCategory(selectedOption.value)
                  }
                />
              </Form.Group>
              <Button
                onClick={handleSubcategorySubmit}
                type="button"
                className="btn btn-primary"
              >
                Add
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {/*-------- model for additional info -----------*/}

      <Modal show={showAddInfo} onHide={() => setShowAddInfo(false)} centered>
        <Modal.Header className="info-header">Additional Info</Modal.Header>
        <Modal.Body>
          <form onSubmit={handleAdditionalInfoSubmit} className="info-form">
            {infoPairs.map((pair, index) => (
              <div key={index} className="info-pair-container">
                <input
                  type="text"
                  placeholder="Specification"
                  value={pair.key}
                  onChange={(e) =>
                    handleInfoInputChange(index, "key", e.target.value)
                  }
                  className="info-input"
                  required
                />
                <input
                  type="text"
                  placeholder="Value"
                  value={pair.value}
                  onChange={(e) =>
                    handleInfoInputChange(index, "value", e.target.value)
                  }
                  className="info-input"
                  required
                />
                {index >= 1 ? (
                  <XCircle
                    className="remove-info-icon"
                    onClick={() => removeInfoPair(index)}
                    style={{ cursor: "pointer", color: "#f44336" }}
                  />
                ) : (
                  <XCircle
                    className="remove-info-icon"
                    onClick={() => removeInfoPair(index)}
                    style={{
                      cursor: "pointer",
                      color: "#f44336",
                      visibility: "hidden",
                    }}
                  />
                )}
              </div>
            ))}
            <Button
              variant="link"
              onClick={addInfoPair}
              className="add-info-btn"
            >
              + Add More
            </Button>
            <div className="final-button">
              <button
                onClick={(e) => (
                  e.preventDefault(),
                  setShowAddInfo(false),
                  setInfoPairs([{ key: "", value: "" }])
                )}
                className="submit-btn"
                style={{ background: "gray" }}
              >
                Clear
              </button>
              <button type="submit" className="submit-btn">
                Done
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/*------ Model for Varient ---------*/}
      <Modal
        size="xl"
        show={showVariant}
        onHide={() => setShowvariant(false)}
        centered
      >
        <Modal.Header className="header">Add Variant</Modal.Header>
        <Modal.Body>
          <form onSubmit={handleVarientSubmit} className="p-3">
            {variants.map((variant, variantIndex) => (
              <div key={variantIndex} className="variant-container">
                <input
                  required
                  type="text"
                  placeholder="Variant Name"
                  value={variant.variantName}
                  style={{ maxWidth: "96%", display: "inline" }}
                  onChange={(e) =>
                    handleVariantChange(
                      variantIndex,
                      null,
                      "variantName",
                      e.target.value
                    )
                  }
                />
                {variantIndex > 0 && (
                  <XCircle
                    className="remove-variant-icon"
                    onClick={() => removeVariant(variantIndex)}
                    style={{
                      cursor: "pointer",
                      marginLeft: "8px",
                      color: "#f44336",
                      fontSize: "20px",
                    }}
                  />
                )}
                {variant.details.map((subVariant, subVariantIndex) => (
                  <div key={subVariantIndex} className="sub-variant-container">
                    <label className="image-upload-label">
                      {subVariant.image ? (
                        <img
                          src={subVariant.image}
                          alt="Sub-variant"
                          className="uploaded-image"
                        />
                      ) : (
                        <div
                          style={{
                            padding: "10px 0px 7px 10px",
                            border: ".2px grey dotted",
                            borderRadius: "50%",
                            marginRight: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <CloudUpload className="image-upload-icon" />
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) =>
                          handleImageUpload(
                            variantIndex,
                            subVariantIndex,
                            e.target.files[0]
                          )
                        }
                        className="image-upload-input"
                        style={{ display: "none" }}
                      />
                    </label>
                    <input
                      type="text"
                      placeholder="Sub-Variant Name"
                      value={subVariant.name}
                      onChange={(e) =>
                        handleVariantChange(
                          variantIndex,
                          subVariantIndex,
                          "name",
                          e.target.value
                        )
                      }
                      className="sub-variant-input"
                      required
                    />
                    <input
                      type="text"
                      placeholder="Price"
                      value={subVariant.price}
                      onChange={(e) =>
                        handleVariantChange(
                          variantIndex,
                          subVariantIndex,
                          "price",
                          e.target.value
                        )
                      }
                      className="sub-variant-input"
                      required
                    />
                    <input
                      type="text"
                      placeholder="Available"
                      value={subVariant.available}
                      onChange={(e) =>
                        handleVariantChange(
                          variantIndex,
                          subVariantIndex,
                          "available",
                          e.target.value
                        )
                      }
                      className="sub-variant-input"
                      required
                    />
                    {subVariantIndex > 0 ? (
                      <XCircle
                        className="remove-sub-variant-icon"
                        onClick={() =>
                          removeSubVariant(variantIndex, subVariantIndex)
                        }
                        style={{
                          cursor: "pointer",
                          marginBottom: "10px",
                          color: "#f44336",
                          fontSize: "20px",
                        }}
                      />
                    ) : (
                      <XCircle
                        className="remove-sub-variant-icon"
                        onClick={() =>
                          removeSubVariant(variantIndex, subVariantIndex)
                        }
                        style={{
                          cursor: "pointer",
                          marginBottom: "10px",
                          color: "#f44336",
                          fontSize: "20px",
                          visibility: "hidden",
                        }}
                      />
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  className="btn btn-outline-primary mt-2"
                  onClick={() => addSubVariant(variantIndex)}
                >
                  + Add Sub-Variant
                </button>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={addVariant}
            >
              + Add Variant
            </button>
            <div className="final-button">
              <button
                onClick={(e) => (
                  e.preventDefault(),
                  setShowvariant(false),
                  setVariants([
                    {
                      variantName: "",
                      details: [
                        { name: "", price: "", available: "", image: "" },
                      ],
                    },
                  ])
                )}
                className="btn btn-soft-primary"
               
              >
                Clear
              </button>
              <button type="submit" className="btn btn-soft-secondary">
                Done
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ModalSubCat
        show={showsub}
        handlesubclose={handlesubclose}
        subcat={subcatdata}
      />
      <ModalCat show={show} handleClose={handleClose} catdata={catdata} />
    </div>
  );
}

export default UpdateProduct;
