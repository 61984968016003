import React, { useState, useEffect, useMemo, useCallback } from "react";
import PageTitle from "../../../components/PageTitle";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Popover as AntPopover, Menu, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  AlertFilled,
  StopOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./table.css";

function Products() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize] = useState(10);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [sortKey, setSortKey] = useState("");
  const navigate = useNavigate();

  const TenantId = localStorage.getItem("TenantId");

  // Fetch products with async/await and error handling
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FOS_CLIENT_API}/products?tenantName=${TenantId}`
        );
        const data = await response.json();
        setProducts(data);
        setFilteredProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
        toast.error("Error fetching products");
      }
    };

    fetchProducts();
  }, [TenantId]);

  // Debounced search handling
  const handleSearchChange = useCallback(
    (event) => {
      const query = event.target.value.toLowerCase();
      setSearchQuery(query);

      const filtered = products.filter((product) =>
        product.name.toLowerCase().includes(query)
      );

      setFilteredProducts(filtered);
      setCurrentPage(1);
    },
    [products]
  );

  // Pagination logic with memoization
  const paginatedProducts = useMemo(() => {
    return filteredProducts.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  }, [filteredProducts, currentPage, pageSize]);

  // Toggle select all checkboxes
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      setSelectedProducts(paginatedProducts.map((product) => product.id));
    } else {
      setSelectedProducts([]);
    }
  };

  const handleCheckboxChange = (productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  // Sorting logic
  const handleSort = useCallback(
    (key) => {
      let sortedProducts = [...filteredProducts];
      switch (key) {
        case "high-review":
          sortedProducts.sort((a, b) => b.reviewCusId - a.reviewCusId);
          break;
        case "new-product":
          sortedProducts.sort((a, b) => a.NewProduct - b.NewProduct);
          break;
        case "price-low-high":
          sortedProducts.sort((a, b) => a.price - b.price);
          break;
        case "price-high-low":
          sortedProducts.sort((a, b) => b.price - a.price);
          break;
        default:
          break;
      }
      setFilteredProducts(sortedProducts);
      setCurrentPage(1);
      setSortKey(key);
    },
    [filteredProducts]
  );

  // Toggle product stock
  const handleToggleStock = async (productId, currentStockStatus) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/products/${productId}?tenantName=${TenantId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ stock: !currentStockStatus }),
        }
      );
      if (!response.ok) throw new Error("Failed to update product stock");

      const updatedProduct = await response.json();
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId ? updatedProduct : product
        )
      );
      setFilteredProducts((prevFilteredProducts) =>
        prevFilteredProducts.map((product) =>
          product.id === productId ? updatedProduct : product
        )
      );

      toast.success(
        updatedProduct.stock ? "Product activated!" : "Product deactivated!"
      );
    } catch (error) {
      toast.error("Error updating product stock status");
    }
  };

  // Delete product confirmation
  const showDeleteConfirm = (productId) => {
    setProductToDelete(productId);
    setIsModalVisible(true);
  };
  const confirmDelete = async () => {
    if (!productToDelete) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/products/${productToDelete}?tenantName=${TenantId}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) throw new Error("Failed to delete product");

      // Only remove the product from the current list and keep the arrangement intact
      const newFilteredProducts = filteredProducts.filter(
        (p) => p.id !== productToDelete
      );
      setFilteredProducts(newFilteredProducts);
      setProducts(products.filter((p) => p.id !== productToDelete));

      toast.success("Product deleted successfully");
    } catch (error) {
      toast.error("Error deleting product");
    } finally {
      setIsModalVisible(false);
      setProductToDelete(null);
    }
  };

  const handleEdit = (productId) => {
    navigate(`/apps/update/${productId}`);
  };

  // Ant Design popover content
  const content = (productId, stock) => (
    <Menu>
      <Menu.Item onClick={() => handleEdit(productId)} icon={<EditOutlined />}>
        Edit
      </Menu.Item>
      <Menu.Item
        onClick={() => handleToggleStock(productId, stock)}
        className={stock ? "text-warning" : "text-success"}
        icon={stock ? <AlertFilled /> : <StopOutlined />}
      >
        {stock ? "Deactivate" : "Activate"}
      </Menu.Item>
      <Menu.Item
        icon={<DeleteOutlined />}
        danger
        onClick={() => showDeleteConfirm(productId)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/store" },
          { label: "Product List", path: "/apps/store", active: true },
        ]}
        title={"Product List"}
      />
      <Row>
        <Card>
          <Card.Body>
            <Row>
              <Col sm={3}>
                <Link to="/forms/product/add" className="btn btn-primary">
                  <i className="uil uil-plus me-1"></i>Add Product
                </Link>
              </Col>
              <Col sm={9}>
                <div className="float-sm-end mt-3 mt-sm-0">
                  <div className="task-search d-inline-block mb-3 mb-sm-0 me-sm-1">
                    <form>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control search-input"
                          placeholder="Search..."
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                        <Button
                          variant="soft-primary"
                          className="input-group-text"
                          type="button"
                        >
                          <i className="uil uil-file-search-alt"></i>
                        </Button>
                      </div>
                    </form>
                  </div>
                  <Dropdown className="d-inline-block" align="end">
                    <Dropdown.Toggle variant="secondary">
                      <i className="uil uil-sort-amount-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleSort("high-review")}>
                        Sort By High Review
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleSort("new-product")}>
                        Sort By New Product
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleSort("price-low-high")}
                      >
                        Price Low to High
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleSort("price-high-low")}
                      >
                        Price High to Low
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <div className="table-container">
            <table className="custom-table">
              <thead className="table-light">
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th>product</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Reviews</th>
                  <th>Discount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {paginatedProducts.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedProducts.includes(product.id)}
                        onChange={() => handleCheckboxChange(product.id)}
                      />
                    </td>
                    <td className="text-start">
                      <div className="product-info">
                        <img
                          style={{
                            width: "70px",
                            height: "70px",
                            objectFit: "cover",
                          }}
                          src={product.imageUrl[0]}
                          alt={product.name}
                          className="product-image image-fluid rounded-circle"
                        />
                        <div>
                          <div className="name">
                            <>{product.name}</>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{product.qty}</td>
                    <td>₹{product.price}</td>
                    <td>{product.reviewCusId}</td>
                    <td>{product.discount ? `${product.discount}%` : "N/A"}</td>
                    <td>
                      <span
                        className={`status ${
                          product.stock ? "active" : "offline"
                        }`}
                      >
                        {product.stock ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td>
                      <AntPopover
                        content={content(product.id, product.stock)}
                        trigger="click"
                      >
                        <span>
                          <FeatherIcon icon="more-vertical" />
                        </span>
                      </AntPopover>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(filteredProducts.length / pageSize) },
              (_, i) => i + 1
            ).map((page) => (
              <Button
                key={page}
                variant={page === currentPage ? "primary" : "light"}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </Button>
            ))}
          </div>
        </Card>

        {/* Delete Confirmation Modal */}
        <Modal
          title="Confirm Deletion"
          visible={isModalVisible}
          onOk={confirmDelete}
          onCancel={() => setIsModalVisible(false)}
          okText="Yes"
          cancelText="No"
        >
          <p>Are you sure you want to delete this product?</p>
        </Modal>
      </Row>
    </div>
  );
}

export default Products;
