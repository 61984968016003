import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmileBeam, faFaceSadTear } from "@fortawesome/free-regular-svg-icons";
import { Spinner } from "react-bootstrap"; 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './style.css'

function DomainCheck() {
  const [domainName, setDomainName] = useState('');
  const [domainStatusDisplay, setDomainStatusDisplay] = useState(false);
  const [domainAvailability, setDomainAvailability] = useState(false);
  const [loading, setLoading] = useState(false);

  function handleCheck(e) {
    e.preventDefault();
    if (domainName.trim() === '') return;
    setLoading(true); 

    fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/check-domain?domainName=${domainName}`)
      .then(response => response.json())
      .then(data => {
        const available = data?.available;
        setDomainAvailability(available);
        setDomainStatusDisplay(true);
        setLoading(false);
      })
      .catch(err => {
        console.error('Error checking domain:', err);
        setDomainStatusDisplay(true);
        setLoading(false);
      });
  }

  return (
    <div className="containerfluid mt-3">
      <div className="container">
        {/* Headline */}
        <h1>Find A Perfect <span>Domain For your store</span></h1>
        
        {/* Search Bar */}
        <div className="domain-search-section">
          <form onSubmit={handleCheck} style={{ display: 'flex' }}>
            <input
              type="text"
              value={domainName}
              onChange={(e) => setDomainName(e.target.value)}
              placeholder="google.com"
              className="search-input"
              required
            />
            <button className="search-button" type="submit" disabled={loading}>
              {loading ? <Spinner as="span" animation="border" size="sm" /> : 'Search Now'}
            </button>
          </form>
        </div>

        {/* Features */}
        <div className="features">
          <div className="feature-box">
            <div className="icon">🌐</div>
            <h4>Instant Activation</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div className="feature-box">
            <div className="icon">💻</div>
            <h4>Reliable Hardware</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
          <div className="feature-box">
            <div className="icon">⚡</div>
            <h4>Fast Support</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </div>
        </div>

        {/* Domain Status */}
        {domainStatusDisplay && (
          <div className="mt-3 text-center">
            {domainAvailability ? (
              <div>
                <p className="text-success">
                  <FontAwesomeIcon icon={faFaceSmileBeam} /> <b>"{domainName}"</b> is available!
                </p>
                <button className="btn btn-outline-light">Add Domain To My store</button>
                <div className="mt-2"><span className="text-light">If you add domain it takes 48 hours to live </span></div>
              </div>
            ) : (
              <div>
                <p className="text-danger">
                  <FontAwesomeIcon icon={faFaceSadTear} /> <b>"{domainName}"</b> is not available.
                </p>

              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DomainCheck;
