import React from "react";
import "./CustomerJourney.css"; // Import the CSS file


function SetUp() {
  return (
    <div  className="w-100  mt-4 cover">
      <div className="">
        <h1 className="text-white">Set Up</h1>
      </div>
    </div>
  );
}

export default SetUp;
