import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Input, Pagination, Button, Form } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Search } = Input;

function ModalCat({ show, handleClose, catdata ,refresh,setRefresh}) {
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editCategoryName, setEditCategoryName] = useState('');

  // Filter data based on the search query
  const filteredData = catdata.filter((category) =>
    category.categoryName.toLowerCase().includes(searchQuery.toLowerCase())
  );
const TenantId=localStorage.getItem('TenantId')
  // Calculate the current items based on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle search input change
  const handleSearch = (value) => {
    setSearchQuery(value);
    setCurrentPage(1); // Reset to first page on new search
  };

  // Handle delete confirmation
  const confirmDelete = (category) => {
    setSelectedCategory(category);
    setIsDeleting(true);
  };

  // Perform delete action
  const handleDelete = async () => {
    if (!selectedCategory) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/category/${selectedCategory.categoryId}?tenantName=${TenantId}`,
        {
          method: 'DELETE',
        }
      );
      if (response.ok) {
        setRefresh(!refresh)
        toast.success('Category deleted successfully!');

      } else {
        toast.error('Failed to delete category.');
      }
    } catch (error) {
      toast.error('An error occurred while deleting the category.');
    } finally {
      setIsDeleting(false);
      setSelectedCategory(null);
    }
  };

  // Handle cancel delete
  const handleCancelDelete = () => {
    setIsDeleting(false);
    setSelectedCategory(null);
  };

  // Handle edit confirmation
  const confirmEdit = (category) => {
    setSelectedCategory(category);
    setEditCategoryName(category.categoryName);
    setIsEditing(true);
  };

  // Perform edit action
  const handleEdit = async () => {
    if (!selectedCategory || !editCategoryName) return;
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/category/${selectedCategory.categoryId}?tenantName=${TenantId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ categoryName: editCategoryName }),
        }
      );
      if (response.ok) {
        setRefresh(!refresh)
        toast.success('Category updated successfully!');

      } else {
        toast.error('Failed to update category.');
      }
    } catch (error) {
      toast.error('An error occurred while updating the category.');
    } finally {
      setIsEditing(false);
      setSelectedCategory(null);
    }
  };

  // Handle cancel edit
  const handleCancelEdit = () => {
    setIsEditing(false);
    setSelectedCategory(null);
  };

  return (
    <div>
   
      {/* Main Category Modal */}
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        backdrop={isDeleting || isEditing ? 'static' : true} // Prevent closing when delete or edit modals are active
        style={{ zIndex: isDeleting || isEditing ? 1040 : 1050 }} // Ensure this modal stays below delete/edit modals
        className={isDeleting || isEditing ? 'modal-blur' : ''}
      >
        <Modal.Header onHide={handleClose} closeButton>
          <Modal.Title as="h5">Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-container">
            <Search
              placeholder="Search categories"
              onSearch={handleSearch}
              enterButton
              style={{ marginBottom: 20, width: 300 }}
            />
            <table className="custom-table">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" />
                  </th>
                  <th>Category</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((category) => (
                  <tr key={category.categoryId}>
                    <td>
                      <input type="checkbox" />
                    </td>
                    <td>{category.categoryName}</td>
                    <td>
                      <i className="icon-button">
                        <FeatherIcon
                          icon="edit"
                          style={{ width: '18px', height: '18px' }}
                          onClick={() => confirmEdit(category)}
                        />
                      </i>
                      <i className="icon-button delete">
                        <FeatherIcon
                          icon="trash"
                          style={{ width: '18px', height: '18px' }}
                          onClick={() => confirmDelete(category)}
                        />
                      </i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              current={currentPage}
              pageSize={itemsPerPage}
              total={filteredData.length}
              onChange={handlePageChange}
              style={{ marginTop: 20, textAlign: 'center' }}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={isDeleting}
        onHide={handleCancelDelete}
        backdrop="static"
        style={{ zIndex: 1050 }} // Ensure this modal is on top
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the category{' '}
          <strong>{selectedCategory?.categoryName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button type="primary" danger onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={handleCancelDelete}>Cancel</Button>
        </Modal.Footer>
      </Modal>

      {/* Edit Confirmation Modal */}
      <Modal
        show={isEditing}
        onHide={handleCancelEdit}
        backdrop="static"
        style={{ zIndex: 1050 }} // Ensure this modal is on top
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Item label="Category Name">
              <Input
                value={editCategoryName}
                onChange={(e) => setEditCategoryName(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button type="primary" onClick={handleEdit}>
            Save
          </Button>
          <Button onClick={handleCancelEdit}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalCat;
