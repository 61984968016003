import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { Card, Col, Row, Button, Alert, Spinner } from "react-bootstrap";
import { Modal } from "antd";
import FeatherIcon from "feather-icons-react";
import { Link, useNavigate } from "react-router-dom";
import bg from "./img/profile lg.png";
import bg2 from "./img/profile4sm.png";
import preview3 from "./img/preview3.png";
import preview2 from "./img/preview5.png";
import preview4 from "./img/preview4.png";
import preview5 from "./img/preview6.png";
import "./ThemeSelect.css"; // Import the CSS file
import { toast } from "react-toastify"; // Import Toastify components

function ThemeSelect() {
  // State to manage modal visibility and selected theme
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [url, seturl] = useState("");
  const [themes, setThemes] = useState([]);
  const [currentTheme, setCurrentTheme] = useState(null);
  const [loadingThemes, setLoadingThemes] = useState(true);
  const [errorThemes, setErrorThemes] = useState(null);
  const tenantId = `${localStorage.getItem("TenantId")}@gmail.com`; // Ensure this matches your tenantId format
  const [domainReq, setDomainReq] = useState(false);
  const navigate = useNavigate();
  // Fetch themes from the backend when the component mounts
  useEffect(
    () => {
      const fetchThemes = async () => {
        try {
          const response = await fetch(
            `${
              process.env.REACT_APP_FOS_CLIENT_API
            }/themes?tenantId=${encodeURIComponent(tenantId)}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch themes");
          }

          const data = await response.json();
          setThemes(data);

          // Determine the current theme. Here, we select the most recently added theme.
          if (data.length > 0) {
            // Sort themes by createdAt in descending order and select the first one
            const sortedThemes = data.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setCurrentTheme(sortedThemes[0]);
          } else {
            setCurrentTheme(null);
          }

          setLoadingThemes(false);
        } catch (err) {
          setErrorThemes(err.message);
          setLoadingThemes(false);
        }
      };

      fetchThemes();
    },
    [tenantId],
    []
  );

  // Function to show modal with selected theme details
  const showModal = (theme) => {
    setSelectedTheme(theme);
    setIsModalVisible(true);
  };

  // Function to handle modal confirmation
  const handleConfirmAdd = () => {
    if (selectedTheme) {
      handleThemeAdd(selectedTheme.name);
    }
    setIsModalVisible(false);
  };

  // Function to handle modal cancellation
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleAddThemeNavigate = () => {
    navigate("/tables/themecontent");
  };

  const handleThemeAdd = async (theme) => {
    if (!theme) return; // Ensure theme is provided

    try {
      // First, fetch the existing themes for the tenant
      const existingThemesResponse = await fetch(
        `${
          process.env.REACT_APP_FOS_CLIENT_API
        }/themes?tenantId=${encodeURIComponent(tenantId)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!existingThemesResponse.ok) {
        throw new Error("Failed to fetch existing themes");
      }

      const existingThemes = await existingThemesResponse.json();

      // If there is 1 or more themes, show a warning and don't proceed
      if (existingThemes.length >= 1) {
        toast.warning("You cannot add more than one theme.");
        return;
      }

      // If no themes exist, proceed with adding the new theme
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/themes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            themeId: theme, // Use the theme parameter
            name: theme, // Use the theme parameter for name
            request: "requested",
            tenantId: tenantId, // Use the tenantId from localStorage
            url: `${url}.shoppingsto.com`,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add theme");
      }
      toast.success(`Theme "${theme}" added successfully!`);

      // Optionally, refresh the themes list after adding a new theme
      setLoadingThemes(true);
      const refreshedResponse = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/themes`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!refreshedResponse.ok) {
        throw new Error("Failed to fetch themes");
      }

      const refreshedData = await refreshedResponse.json();
      setThemes(refreshedData);

      if (refreshedData.length > 0) {
        const sortedThemes = refreshedData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCurrentTheme(sortedThemes[0]);
      } else {
        setCurrentTheme(null);
      }

      setLoadingThemes(false);
    } catch (err) {
      toast.error(`Error: ${err.message}`);
      setLoadingThemes(false);
    }
  };

  // Define theme details for available themes to add
  const availableThemes = [
    {
      name: "Fusion",
      previewImage: preview3,
      previewLink: "https://demo1.shoppingsto.com",
      detailsImage: preview3, // Replace with actual detailed image if available
      description:
        "Fusion is a versatile theme suitable for various online stores.",
    },
    {
      name: "Zenith",
      previewImage: preview5,
      previewLink: "https://demo3.shoppingsto.com",
      detailsImage: preview5,
      description:
        "Zenith offers a clean and modern design for your storefront.",
    },
    {
      name: "Cascade",
      previewImage: preview4,
      previewLink: "https://demo.shoppingsto.com",
      detailsImage: preview4,
      description:
        "Cascade provides a dynamic layout with customizable sections.",
    },
    {
      name: "Radiant",
      previewImage: preview2,
      previewLink: "https://demo2.shoppingsto.com",
      detailsImage: preview2,
      description: "Radiant features vibrant colors and interactive elements.",
    },
  ];

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/Themes" },
          { label: "Customers", path: "/apps/Themes", active: true },
        ]}
        title={"Themes"}
      />
      <Card>
        <Card.Body>
          <div className="row">
            <img className="col-8" src={bg} alt="Background Large" />
            <img className="col-4" src={bg2} alt="Background Small" />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header className="bg-white">
          <FeatherIcon icon="credit-card" className="text-primary me-1" />
          Current Theme
        </Card.Header>
        <Card.Body>
          {loadingThemes ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : errorThemes ? (
            <Alert variant="danger">Error: {errorThemes}</Alert>
          ) : currentTheme ? (
            <div className="row">
              <div className="col-3  p-2">
                <img
                  className="w-50"
                  src={
                    availableThemes.find(
                      (theme) => theme.name === currentTheme.themeId
                    )?.previewImage || bg
                  }
                  alt={currentTheme.name}
                />
              </div>
              <div className="col-7">
                <strong>{currentTheme?.name}</strong>
                <div>
                  <small>
                    Added: {new Date(currentTheme?.createdAt).toLocaleString()}
                  </small>
                </div>
                <div className="mt-1">
                  <span>
                    {currentTheme.name} Version{" "}
                    {/* If you have version info, include it here */}
                  </span>

                  {themes[0]?.request === "activated" ? (
                    <div className="alert alert-success mt-3">
                      Your Link Activated , click to check the link
                    </div>
                  ) : (
                    <div className="alert alert-warning mt-3" role="alert">
                      I takes 24 hours to update the theme. If you have any
                      questions, please contact us.
                    </div>
                  )}

                  <div>
                    <span className="text-primary">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`http://${currentTheme?.url}`}
                      >
                        {currentTheme?.url}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="mt-1">
                  {themes[0]?.request === "activated" ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`http://${themes[0].url}`}
                    >
                      <span className="btn btn-sm btn-soft-success rounded-pill">
                        Activated
                      </span>
                    </a>
                  ) : (
                    <Button className="btn btn-sm btn-soft-secondary rounded-pill">
                      Requested
                    </Button>
                  )}
                  {/* <span
                    className="btn btn-sm btn-soft-secondary rounded-pill"
                  >
                    In progress
                  </span> */}
                </div>
              </div>
            </div>
          ) : (
            <Alert variant="info">No themes selected.</Alert>
          )}
        </Card.Body>
      </Card>

      <Card>
        <Card.Header className="bg-white">
          <FeatherIcon icon="layout" className="text-primary me-1" />
          Themes
        </Card.Header>
        <Card.Body>
          <Row>
            {availableThemes.map((theme, index) => (
              <Col md={4} key={index} className="mb-4">
                <Card className="theme-card-container">
                  <Card.Body>
                    <div className="text-center position-relative">
                      <img
                        className="w-75"
                        src={theme.previewImage}
                        alt={theme.name}
                      />
                      <div className="theme-preview-overlay">
                        <a
                          className="text-white"
                          href={theme.previewLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FeatherIcon icon="layout" className="fs-1 me-1" />
                          Click to Preview
                        </a>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer className="bg-white d-flex align-items-center justify-content-between">
                    <div>
                      <Link to="#">{theme.name}</Link>
                    </div>
                    <Button
                      className="btn btn-sm btn-soft-info text-end"
                      onClick={() => showModal(theme)}
                    >
                      Select
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>

      {/* Modal for Theme Details */}
      <Modal
        title={selectedTheme ? selectedTheme.name : "Theme Details"}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className="w-50"
      >
        {selectedTheme && (
          <div>
            <div className="w-50 test-center">
              <div className="form-label">Store Domain</div>
            </div>
            <div className="input-container">
              <input
                onChange={(e) => seturl(e.target.value)}
                name=""
                placeholder="yourstore.com"
                required
              />
              <button className="invite-btn">.shoppingsto.com</button>
            </div>
            <div className="text-end mt-4 d-flex align-item-center justify-content-between">
              <div>
                {" "}
                <button
                  onClick={handleAddThemeNavigate}
                  className="btn btn-outline-primary"
                >
                  Add Your Store Details For your WebSite
                </button>
              </div>
              <div>
                {" "}
                <button className="btn btn-soft-primary" onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  onClick={handleConfirmAdd}
                  className="ms-2 btn btn-soft-primary"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default ThemeSelect;
