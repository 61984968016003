import React, { useEffect, useState } from "react";
import { Table, Tag, Button, message } from "antd";

const ThemesTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch data from the endpoint using the fetch API
    fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/themes`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching the data:", error);
      });
  }, []);

  // Function to update the request field to "activated"
  const updateRequestStatus = (id) => {
    fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/themes/${id}`, {
      method: "PUT", // Assuming you're using PATCH to update the status
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ request: "activated" }), // Sending the new status
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update the status");
        }
        return response.json();
      })
      .then((updatedTheme) => {
        message.success("Status updated successfully");
        // Update the local data state to reflect the change
        setData((prevData) =>
          prevData.map((theme) =>
            theme.id === updatedTheme.id ? updatedTheme : theme
          )
        );
      })
      .catch((error) => {
        console.error("Error updating the status:", error);
        message.error("Failed to update the status");
      });
  };

  // Define the table columns
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Theme ID",
      dataIndex: "themeId",
      key: "themeId",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => <Tag color="geekblue">{name}</Tag>, // Tag for better design
    },
    {
      title: "Request",
      dataIndex: "request",
      key: "request",
      render: (request) => (
        <Tag color={request === "requested" ? "green" : "volcano"}>{request}</Tag>
      ),
    },
    {
      title: "Tenant ID",
      dataIndex: "tenantId",
      key: "tenantId",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (url) => (
        <a href={`http://${url}`} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => updateRequestStatus(record.id)}
          disabled={record.request === "activated"} // Disable the button if already activated
        >
          {record.request === "activated" ? "Activated" : "Activate"}
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
        scroll={{ x: 800 }}
      />
    </div>
  );
};

export default ThemesTable;
