import React, { useState, useEffect } from 'react';
import { Table, Input, Card, Space, Tag, Button, message, Popconfirm, Popover } from 'antd';
import { SearchOutlined, EllipsisOutlined, MailOutlined, EyeOutlined, PoweroffOutlined } from '@ant-design/icons';
import moment from 'moment';

function AdminVendor() {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  // Fetch admin/vendor data from the endpoint
  useEffect(() => {
    fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/admin`)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setFilteredData(data); // Initial data to be displayed
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // Handle search input change
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
    
    // Filter the data based on the search query
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase()) ||
      item.email.toLowerCase().includes(value.toLowerCase()) ||
      item.phone.includes(value)
    );
    setFilteredData(filtered);
  };

  // Update admin/vendor's active status
  const handleStatusChange = (id, isActive) => {
    const newStatus = !isActive; // Toggle status
    fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/admin/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isActive: newStatus }),
    })
      .then((response) => {
        if (response.ok) {
          setData((prevData) =>
            prevData.map((item) =>
              item.id === id ? { ...item, isActive: newStatus } : item
            )
          );
          setFilteredData((prevData) =>
            prevData.map((item) =>
              item.id === id ? { ...item, isActive: newStatus } : item
            )
          );
          message.success(`Admin ${newStatus ? 'activated' : 'deactivated'} successfully!`);
        } else {
          throw new Error('Failed to update status');
        }
      })
      .catch((error) => message.error('Error updating status: ' + error));
  };

  // Handle sending mail (stubbed function for now)
  const handleSendMail = (email) => {
    // You can implement the email sending logic here (e.g., calling an email API)
    message.info(`Mail sent to ${email}`);
  };

  // Define columns for the table
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('YYYY-MM-DD HH:mm'), // Format the date
    },
    {
      title: 'Active Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive) => (
        <Tag color={isActive ? 'green' : 'red'}>
          {isActive ? 'Active' : 'Inactive'}
        </Tag>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => <Tag color="blue">{role}</Tag>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Popover
            content={
              <Space direction="vertical">
              
                <Popconfirm
                  title={`Are you sure to ${record.isActive ? 'deactivate' : 'activate'} this admin?`}
                  onConfirm={() => handleStatusChange(record.id, record.isActive)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<PoweroffOutlined />} danger={record.isActive}>
                    {record.isActive ? 'Deactivate' : 'Activate'}
                  </Button>
                </Popconfirm>
                <Button icon={<MailOutlined />} onClick={() => handleSendMail(record.email)}>
                  Send Mail
                </Button>
              </Space>
            }
            trigger="click"
          >
            <Button icon={<EllipsisOutlined />} />
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <Card style={{ margin: '20px' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          placeholder="Search by name, email, or phone"
          value={searchText}
          onChange={handleSearch}
          prefix={<SearchOutlined />}
          allowClear
        />

        <Table
          columns={columns}
          dataSource={filteredData}
          rowKey="id"
          pagination={{ pageSize: 7}} // Paginate 5 records per page
        />
      </Space>
    </Card>
  );
}

export default AdminVendor;
