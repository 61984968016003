import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { toast } from 'react-toastify'; // Make sure to import toast from react-toastify
import { useNavigate } from "react-router-dom";
function AddBlog() {
  const [blogTitle, setBlogTitle] = useState('');
  const [blogContent, setBlogContent] = useState('');
  const [blogImage, setBlogImage] = useState(null);
  const [contentList, setContentList] = useState([]);
  const [imageUrls, setImageUrls] = useState([]); // Store uploaded image URLs
  const navigate = useNavigate();
  // Handle Image Upload
  const handleImageUpload = async (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const urls = await handleImageUploadPost(files);
      setBlogImage(urls[0]); // Assuming you want to use the first uploaded image
    }
  };
  const handleImageUploadPost = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/upload`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload images");
      }

      const result = await response.json();
      return result.urls; // Array of uploaded image URLs
    } catch (error) {
      toast.error("Image upload failed");
      console.error(error);
      throw error;
    }
  };
  // Post Blog Data
  const handleSubmit = async () => {
    const blogData = {
      heading: blogTitle,
      content: blogContent,
      imageUrl: blogImage,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/blogs?tenantName=${localStorage.getItem('TenantId')}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(blogData),
      });

      if (!response.ok) {
        throw new Error('Failed to create blog');
      }

      toast.success('Blog created successfully');
      navigate(`/tables/blogpost`);
      // Optionally, reset form fields here
      setBlogTitle('');
      setContentList([]);
      setBlogImage(null);
    } catch (error) {
      toast.error('Blog creation failed');
      console.error(error);
    }
  };

  return (
    <div className="container my-4">
      <h3>Create Blog</h3>
      
      {/* Blog Form */}
      <Card className="p-4 mb-4">
        <Form>
          {/* Blog Title */}
          <Form.Group className="mb-3" controlId="formBlogTitle">
            <Form.Label>Blog Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter blog title"
              value={blogTitle}
              onChange={(e) => setBlogTitle(e.target.value)}
            />
          </Form.Group>

          {/* Blog Image Upload */}
          <Form.Group className="mb-3" controlId="formBlogImage">
            <Form.Label>Upload Blog Image</Form.Label>
            <Form.Control type="file" onChange={handleImageUpload} />
            {blogImage && (
              <div className="mt-3 w-25 border rounded">
                <img src={blogImage} alt="Blog Preview" style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} />
              </div>
            )}
          </Form.Group>

          {/* Blog Content */}
          <Form.Group className="mb-3" controlId="formBlogContent">
            <Form.Label>Blog Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Write your blog content..."
              value={blogContent}
              onChange={(e) => setBlogContent(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" onClick={handleSubmit} className="ms-2">
            Submit Blog
          </Button>
        </Form>
      </Card>

      {contentList.length > 0 && (
        <div className="mb-4">
          <h4>Added Content</h4>
          <ul className="list-group">
            {contentList.map((content, index) => (
              <li key={index} className="list-group-item">
                {content}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default AddBlog;
