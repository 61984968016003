import React, { useState } from 'react';
import axios from 'axios'; // Import axios for HTTP requests
import './Feedback.css';
import PageTitle from '../../../components/PageTitle';
import { CheckCircle } from 'react-bootstrap-icons';

function FeedBack() {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // To handle error messages

  const handleSubmit = async () => {
    if (rating && feedback) {
      try {
        const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/survey`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            rating: rating.toString(),
            comments: feedback,
            name: `${localStorage.getItem('TenantId')}@gmail.com`
          })
        });
  
        if (response.ok) {
          setSubmitted(true);
          const data = await response.json();
          console.log('Survey successfully submitted:', data);
        } else {
          setErrorMessage('Error submitting feedback. You can only submit once.');
          console.error('Error submitting feedback:', await response.text());
        }
      } catch (error) {
        setErrorMessage('Error submitting feedback. Please try again.');
        console.error('Error submitting feedback:', error);
      }
    } else {
      // Shake animation if fields are missing
      document.querySelector('.feedback-box').classList.add('shake');
      setTimeout(() => {
        document.querySelector('.feedback-box').classList.remove('shake');
      }, 500);
    }
  };
  

  if (submitted) {
    return (
      <div>
        <PageTitle
          breadCrumbItems={[
            { label: "app", path: "/apps/Feedback" },
            { label: "Feedback", path: "/apps/Feedback", active: true },
          ]}
          title={"We'd Love Your Feedback!"}
        />
        <div className="feedback-container container mt-2 text-center w-75">
          <div className="feedback-completed animated fadeIn ">
            <h2>Thank You for Your Feedback!</h2>
            <p>We appreciate your thoughts and will use them to improve our product!</p>
            <div className="success-icon animated pulse text-center">
              <CheckCircle className='mt-1' style={{color:"green",fontSize:'50px'}}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="feedback-container">
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/Feedback" },
          { label: "Feedback", path: "/apps/Feedback", active: true },
        ]}
        title={"We'd Love Your Feedback!"}
      />
      <div className="feedback-box animated fadeIn">
        <h2 className="header-text">
          Hi John Doe, we're constantly working to enhance your experience.
        </h2>
        <p className="subtext">
          Your insights will help us shape the future of our product.
        </p>

        <div className="rating-container animated zoomIn">
          <p className="rating-text">
            How would you rate your overall satisfaction with our product?
          </p>
          <div className="rating-buttons">
            {Array.from({ length: 10 }, (_, i) => (
              <button
                key={i}
                className={`rating-button ${rating === i + 1 ? 'selected' : ''}`}
                onClick={() => setRating(i + 1)}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>

        <div className="feedback-input animated slideInUp">
          <p>Briefly explain your experience or any suggestions for improvement</p>
          <textarea
            placeholder="Describe your experience here"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>

        {errorMessage && <p className="error-text">{errorMessage}</p>} {/* Display error message */}

        <div className="feedback-actions animated fadeInUp">
          <button className="action-button submit" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default FeedBack;
