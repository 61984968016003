import React from "react";
import PageTitle from "../../../components/PageTitle";
import {
  Card,
  Row,
  Col,
  Container,
  ListGroup,
  Badge,
} from "react-bootstrap";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";

function VendorDetails({ vendorData }) {
  const socialMediaIcons = {
    Facebook: <FaFacebookF />,
    Twitter: <FaTwitter />,
    LinkedIn: <FaLinkedinIn />,
    Instagram: <FaInstagram />,
  };

  return (
    <div>
      <Container fluid className="my-4">
        <PageTitle
          breadCrumbItems={[
            { label: "app", path: "/apps/PageContent" },
            { label: "Customers", path: "/apps/Orders", active: true },
          ]}
          title={"Vendor Details"}
        />

        <Card className="shadow-sm border-0">
          {/* Header Section with Company Logo */}
          <Card.Header className="bg-dark text-white text-center p-4">
            <img
              src={vendorData?.companyLogo}
              alt={`${vendorData?.companyName} Logo`}
              className="img-fluid mb-3 rounded-circle"
              style={{ maxHeight: "100px" }}
            />
            <h2 className="mb-2 text-white">{vendorData?.companyName}</h2>
            <Badge pill variant="info">
              Vendor
            </Badge>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col md={8}>
                <h4 className="mt-4 mb-3">Company Details</h4>
                <div
                  className="mb-4 text-muted"
                  dangerouslySetInnerHTML={{
                    __html: vendorData?.companyDetails,
                  }}
                />
                <h5 className="mb-3">Headquarters</h5>
                <p className="mb-4 text-muted">
                  <FaMapMarkerAlt className="me-2" />{" "}
                  {vendorData?.branch?.headquarters}
                </p>

                <h5 className="mb-3">Branches</h5>
                <ListGroup variant="flush">
                  {vendorData?.branch?.branches.map((branch, index) => (
                    <ListGroup.Item
                      key={index}
                      className="d-flex justify-content-between align-items-center mb-2 border-0 rounded-3 shadow-sm"
                    >
                      {branch}
                    </ListGroup.Item>
                  ))}
                </ListGroup>

                <h5 className="mt-4 mb-3">Policy</h5>
                <div
                  className="mb-4 text-muted"
                  dangerouslySetInnerHTML={{
                    __html: vendorData?.policy,
                  }}
                />
              </Col>

              <Col md={4}>
                <div className="d-flex flex-column align-items-center p-3 border rounded-3 shadow-sm">
                  <h5 className="mt-3 mb-3">Contact Info</h5>
                  <p className="mb-2">
                    <FaEnvelope className="me-2 text-primary" />{" "}
                    {vendorData?.email}
                  </p>
                  <p className="mb-4">
                    <FaPhone className="me-2 text-primary" />{" "}
                    {vendorData?.contact}
                  </p>

                  <h5 className="mb-3">Social Media</h5>
                  <div className="d-flex justify-content-around">
                    {vendorData?.socialMedia.map((link, index) => {
                      const platform = link.includes("facebook")
                        ? "Facebook"
                        : link.includes("twitter")
                        ? "Twitter"
                        : link.includes("linkedin")
                        ? "LinkedIn"
                        : link.includes("instagram")
                        ? "Instagram"
                        : "Other"; // Fallback for unrecognized platforms

                      return (
                        <a
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                          className="text-dark  ms-2"
                        >
                          {socialMediaIcons[platform] || <FaFacebookF />}
                        </a>
                      );
                    })}
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

export default VendorDetails;
