import React from 'react';
import { Button, Label, FormGroup, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Design from './design/Design';
import Bg from './design/Bg';
import './design/popup.css'
import logo from '../../landing/logo5.png';

const LoginDemo = () => {
  const navigate = useNavigate();
  const initialValues = {
    email: '',
    password: '',
    role: 'EMPLOYEE',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().required('Password is required'),
    role: Yup.string().required('Role is required'),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log('Form Values:', values);
    try {
      const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      console.log(response, "response");
   

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const user = {
        id: 1,
        email: "shreyu@coderthemes.com",
        username: "test",
        password: "test",
        firstName: "Test",
        lastName: "User",
        role: "Admin",
        token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI"
      };
      const data = await response.json();
      console.log('API Response:', data);
      sessionStorage.setItem('admin', data.id);

        console.log("Email already exists, navigating to home page.");
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('url', JSON.stringify(user));
        const tenantId = data.tenantName.replace(/@.*$/, '');
        localStorage.setItem('TenantId', tenantId);
        if (data.role === 'ADMIN') {
          navigate("/apps/vendors");
        } else if (data.introcheck === "false") {
          navigate("/intro");
        } else {
          navigate("/");
        }
        window.location.reload();
    } catch (error) {
      console.error('Login failed:', error.message);
      message.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };
  
  

  return (
    <div className="loginBox ">
      <Bg />
      <Design />
      <Container style={{ height: '100vh', overflow: 'hidden' }} fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col lg="6"></Col>
          <Col style={{ zIndex: '23' }} lg="6" className="p-0">
            <Card
              style={{
                height: '100vh',
                borderTopLeftRadius: '2rem',
                borderBottomLeftRadius: '2rem',
              }}
            >
              <CardBody className=" m-1 p-5">
              <div className='text-center'><img src={logo} style={{width:'150px'}} alt="jhgk" /></div>
                <center className="mb-2">
                  <h1 className="mb-0 fw-bold">LogIn</h1>
                </center>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ errors, touched }) => (
                    <Form >
                      <FormGroup>
                        <Label htmlFor="email">Email</Label>
                        <Field
                          name="email"
                          type="text"
                          style={{ height: '50px' }}
                          placeholder="Enter your email"
                          className={`form-control${
                            errors.email && touched.email ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="password">Password</Label>
                        <Field
                          name="password"
                          type="password"
                          placeholder="Enter your password"
                          className={`form-control${
                            errors.password && touched.password ? ' is-invalid' : ''
                          }`}
                          style={{ height: '50px' }}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                      <FormGroup className="form-check d-flex" inline>
                        {/* <Label check className="d-flex">
                          <div className='me-3'>
                            <input type="checkbox"></input>
                          </div>
                          Remember me
                        </Label> */}
                        <div className="ms-auto text-decoration-none">
                          <sapn className="pb-4 d-block">
                            Dont have an account? <Link to="/auth/register">Register</Link>
                          </sapn>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Button 
                          type="submit"
                          style={{ backgroundColor: 'black' }}
                          className=" w-100 py-2 text-light"
                        >
                          Submit
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                  
                </Formik>
                <div className="d-flex align-items-center px-3">
                  <hr className="flex-grow-1 bg-secondary" />
                  <span className="mx-3 text-dark">or</span>
                  <hr className="flex-grow-1 bg-secondary" />
                </div>

                <div className="mb-3 d-flex gap-2 ">
                  <Button
                    style={{ backgroundColor: '#db4437' }}
                    className="w-100 py-2 text-light"
                    onClick={() =>
                      window.location.href = `${process.env.REACT_APP_FOS_CLIENT_API}/auth/google`
                    }
                  >
                    <i className="fab fa-google me-2"></i> Sign in with Google
                  </Button>
                  <Button
                    style={{ backgroundColor: '#3b5998' }}
                    className="w-100 py-2 text-light"
                    onClick={() => {
                      // Redirect to your NestJS Facebook authentication endpoint
                      window.location.href = `${process.env.REACT_APP_FOS_CLIENT_API}/auth/facebook`;
                    }}
                  >
                    <i className="fab fa-facebook-f me-2"></i> Sign in with Facebook
                  </Button>
                </div>


              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};


export default LoginDemo;
