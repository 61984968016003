import React from 'react'
import PageTitle from '../../../components/PageTitle'
import ChatApp from '../../apps/Chat'

function SupportCus() {
  return (
    <div>
     <ChatApp/>
    </div>
  )
}

export default SupportCus