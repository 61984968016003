import img from "./Login.png";
import img2 from "./Frame.png";
import "./Landing.css";
import ConBody from "./ConBody";
import Pricing from "../other/Pricing";
import logo from "./logo5.png";
import FeatherIcon from "feather-icons-react";
function LandingPage({ handleLandingPage }) {
  return (
    <div>
      <ConBody handleLandingPage={handleLandingPage} />
      <div className="">
        <div className="card-container">
          <div className="card">
            <img src={img} alt="Profile 1" className="card-image" />
          </div>
          <div className="main-content">
            <h1>Choose & customize a theme</h1>
            <p>
              Customize the design of your shop from over thousands of themes.
              No design experience or programming skills required.
            </p>

            <button onClick={handleLandingPage} className="explore-button">
              Start Now
            </button>
          </div>
        </div>
        <Pricing />
        <section className="features-section mt-4 ">
          <h1>E-commerce Applications</h1>
          <div className="feature-cards">
            <div className="feature-card text-start">
              <h3>Key Features of Our E-commerce Applications:</h3>
              <p>
                <strong>Search Functionality : </strong>
                <span>
                  Allows customers to quickly find specific products using
                  keywords or filters.
                </span>
              </p>
              <p>
                <strong>Payment Gateway : </strong>{" "}
                <span>
                  Securely processes payments using various methods like credit
                  cards, digital wallets, and bank transfers.
                </span>
              </p>
              <p>
                <strong> Customer Account : </strong>{" "}
                <span>
                  Provides a personalized experience with features like order
                  history, saved addresses, and wishlists.
                </span>
              </p>
            </div>
            <div className="feature-card text-start">
              <h3>Benefits of E-commerce Applications</h3>
              <p>
                <strong>Product Catalog : </strong>
                <span>
                  A comprehensive list of products with detailed descriptions,
                  images, and pricing information.
                </span>
              </p>
              <p>
                <strong> Shopping Cart : </strong>{" "}
                <span>
                  Enables customers to add items to their virtual shopping cart
                  and proceed to checkout.
                </span>
              </p>
              <p>
                <strong> Shipping and Delivery : </strong>{" "}
                <span>Handles order fulfillment, shipping, and tracking.</span>
              </p>
            </div>
          </div>
          <div className="feature-cards mt-3">
            <div className="feature-card text-start">
              <h3>
                Businesses that can benefit from switching to e-commerce
                include:
              </h3>
              <p>
                <strong>Wholesalers : </strong>
                <span>
                  Businesses that sell products in bulk to other businesses can
                  use e-commerce to reach a wider range of customers.
                </span>
              </p>
              <p>
                <strong> Retailers : </strong>{" "}
                <span>
                  Traditional brick-and-mortar stores can expand their reach and
                  customer base by selling online.
                </span>
              </p>
              <p>
                <strong>Service Providers : </strong>{" "}
                <span>
                  Businesses offering services like consulting, tutoring, or
                  digital marketing can sell their services online.
                </span>
              </p>
            </div>
            <div className="feature-card text-start">
              <h3>A Digital Marketplace of E-commerce Applications</h3>
              <p>
                <strong>Subscription-Based Businesses : </strong>
                <span>
                  Businesses that offer recurring subscriptions, such as meal
                  kits or beauty boxes, can thrive in the e-commerce space.
                </span>
              </p>
              <p>
                <strong> Artists and Craftspeople : </strong>{" "}
                <span>
                  E-commerce platforms provide a way for artists and
                  craftspeople to showcase and sell their work to a global
                  audience.
                </span>
              </p>
              <p>
                <strong> Food and Beverage Businesses : </strong>{" "}
                <span>
                  Restaurants, bakeries, and breweries can offer online ordering
                  and delivery services.
                </span>
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className="card-container">
        <div className="main-content">
          <h1>About Us</h1>
          <p>
            Shoppingsto is an innovative technology industry focused, business
            driven professional services firm. From Ecommerce development to
            custom Shopping applications, our talented people work intelligently
            to solve and manage client’s needs. We serve to deliver cost
            effective interactive technology and design solutions without
            compromising on quality.
          </p>

          <button onClick={handleLandingPage} className="explore-button">
            Start Now
          </button>
        </div>
        <div className="cardss float-end">
          <img
            src={img2}
            style={{ width: "90%" }}
            alt="Profile 1"
            className="card-image"
          />
        </div>
      </div>
      <footer className="footer-container">
        <div className="footer-left  p-2">
          <div className="logo-section"></div>
          {/* <div className="description">
          <h3>Professional Web Design</h3>
          <p>
            Hi! My name is Dmitrii Rogoza and I’m an expert in web design and
            branding. I can help you make your website more attractive.
          </p>
        </div> */}
        </div>
        <div className="footer-right">
          <div>
            <img src={logo} className="w-25 img-fluid" alt="" />
          </div>
          <div className="">
            <div>
              <h4>Follow us</h4>
            </div>
            <div className="">
              <div>
                <FeatherIcon icon="instagram" className="text-light ms-2" />
                <FeatherIcon icon="facebook" className="text-light ms-2" />
                <FeatherIcon icon="linkedin" className="text-light ms-2" />
                <FeatherIcon icon="youtube" className="text-light ms-2" />
              </div>
            </div>
          </div>
          <div className="footer-subscribe">
            <h3>Subscribe</h3>
            <p>
              Subscribe to stay tuned for new web design and latest updates.
              Let's do it!
            </p>
            <form className="subscribe-form">
              <input type="email" placeholder="Enter your email address" />
              <button type="submit">Subscribe</button>
            </form>
          </div>
          <div className="footer-links">
            <div className="column">
              <h4>Product</h4>
              <ul>
                <li>Landing Page</li>
                <li>Popup Builder</li>
                <li>Web-design</li>
                <li>Content</li>
                <li>Integrations</li>
              </ul>
            </div>
            <div className="column">
              <h4>Use Cases</h4>
              <ul>
                <li>Web-designers</li>
                <li>Marketers</li>
                <li>Small Business</li>
                <li>Website Builder</li>
              </ul>
            </div>
            <div className="column">
              <h4>Resources</h4>
              <ul>
                <li>Academy</li>
                <li>Blog</li>
                <li>Themes</li>
                <li>Hosting</li>
                <li>Developers</li>
                <li>Support</li>
              </ul>
            </div>
            <div className="column">
              <h4>Company</h4>
              <ul>
                <li>About Us</li>
                <li>Careers</li>
                <li>FAQs</li>
                <li>Teams</li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
