// src/components/OAuthCallback.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import jwtDecode from "jwt-decode";

function OAuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    // Extract token from URL
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const user = {
        id: 1,
        email: "shreyu@coderthemes.com",
        username: "test",
        password: "test",
        firstName: "Test",
        lastName: "User",
        role: "Admin",
        token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI"
      };
    if (token) {
      // Store token (you can use localStorage or a state management solution)
      const decodedToken = jwtDecode(token);
      localStorage.setItem("token", token);
      localStorage.setItem('url', JSON.stringify(user));
      const tenantId = decodedToken.email.replace(/@.*$/, '');
      localStorage.setItem('TenantId', tenantId);
      sessionStorage.setItem('admin', decodedToken.sub);
      console.log(decodedToken,"asmi ");
      
      if (decodedToken.role === 'ADMIN') {
        navigate("/apps/vendors");
      } else if (decodedToken.introcheck === "false") {
        navigate("/intro");
      } else {
        navigate("/");
      }
      window.location.reload();
     
    } else {
      message.error("Google login failed!");
      navigate("/auth/login");
    }
  }, [navigate]);

  return (
    <div>
      <h2>Processing login...</h2>
    </div>
  );
}

export default OAuthCallback;
