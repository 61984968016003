import React from 'react'
import jwtDecode from 'jwt-decode';
function AdminSupports() {



const token = localStorage.getItem('token');

if (token) {
  const decodedToken = jwtDecode(token);  // Decode the JWT
  const userRole = decodedToken;  // Assuming the role is inside the JWT payload
  console.log(userRole);
}
  return (
    <div>AdminSupports</div>
  )
}

export default AdminSupports