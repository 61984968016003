import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { Spin } from "antd"; // Import Spin from Ant Design
import { LoadingOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons"; // Import the icons from Ant Design
import "./conbody.css";
import img from "./OBJECTS.png"; // Import your image file correctly
import logo from "./logo5.png";
import pattern from "./Strip-Patterns.png";

function ConBody({ handleLandingPage }) {
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false); // Track loading state
  const [icon, setIcon] = useState(null); // To track which icon to show after sending email

  const handleEmailSend = async () => {
    const email = document.querySelector(".email-input").value; // Get the email input value
    const name = email.replace("@gmail.com", "");

    if (!email) {
      setMessage("Please enter a valid email address.");
      setError(true);
      setIcon(<CloseCircleOutlined style={{ fontSize: 16, color: "white" }} />);
      return;
    }

    setLoading(true); // Start loading spinner
    setMessage(""); // Clear any previous messages

    try {
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/mail/send-email?to=${email}&username=${name}&code=XD%FY7HGFR`
      );

      if (!response.ok) throw new Error("Failed to send email");

      setMessage("Email sent successfully! Check your inbox.");
      setError(false);
      setIcon(<CheckCircleOutlined style={{ fontSize: 16, color: "white" }} />); // Show success icon
    } catch (error) {
      setMessage("Error sending email. Please try again.");
      setError(true);
      setIcon(<CloseCircleOutlined style={{ fontSize: 16, color: "white" }} />); // Show error icon
    } finally {
      setLoading(false); // Stop loading spinner

      // Hide message after 3 seconds
      setTimeout(() => {
        setMessage("");
        setIcon(null); // Reset the icon after 3 seconds
      }, 3000);
    }
  };

  return (
    <div style={{ position: "relative" }} className="main-container">
      <img
        className="img-fluid"
        src={pattern}
        style={{
          position: "absolute",
          zIndex: "1",
          top: "-200px",
          left: "-100px",
          filter: "blur(8px)",
        }}
        alt=""
      />

      {/* React Bootstrap Navbar */}
      <Navbar expand="md" className="navbar">
        <Container>
          <Navbar.Brand href="#home">
            <img src={logo} alt="Logo" className="logo-img" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home">Home</Nav.Link>
              <Nav.Link href="#products">Products</Nav.Link>
              <Nav.Link href="#support">Support</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <button
                onClick={handleLandingPage}
                className="quote-button text-light btn"
              >
                Login
              </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div
        style={{ zIndex: "8" }}
        className="hero-section d-flex flex-column flex-md-row align-items-center p-4"
      >
        <div style={{ zIndex: "8" }} className="text-content mb-md-0">
          <h1 className="mb-4">
          Launch your Website Today, <span className="highlight">Sell Tomorrow.</span>
          </h1>
          <h3>Building your own online store in easy steps.</h3>
          <p className="description">
            Just your email, agree to receive advertising emails from Shoppingsto
          </p>
          <div className="email-input-container d-flex align-items-center gap-2">
            <input
              type="email"
              name="email"
              placeholder="Your email address"
              className="email-input"
            />
            <Button onClick={handleEmailSend} className="download-button">
              {loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined style={{ fontSize: 16, color: "white" }} spin />
                  }
                />
              ) : (
                icon || ( // Show icon (success or error) or default content
                  <>
                    Start <i className="send-icon"></i>
                  </>
                )
              )}
            </Button>
          </div>

    
        </div>
        <div className="image-content">
          <img
            style={{ zIndex: "5" }}
            src={img}
            alt="3D Shapes"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  );
}

export default ConBody;
