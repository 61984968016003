import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { Card, Button, Form, Dropdown, Col, Row } from "react-bootstrap"; // Import Bootstrap for styling
import { Link } from "react-router-dom";
import axios from "axios"; // To make HTTP requests

function BlogPost() {
  const [blogs, setBlogs] = useState([]); // State to store fetched blog posts
  const [loading, setLoading] = useState(true); // State for loading indication
  const [error, setError] = useState(null); // State for handling errors

  useEffect(() => {
    // Fetch blog posts when the component mounts
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/blogs?tenantName=${localStorage.getItem('TenantId')}`);
        if (!response.ok) {
          throw new Error("Failed to fetch blogs");
        }
        const data = await response.json();
        setBlogs(data); // Set the blogs data
        setLoading(false); // Stop loading when data is fetched
      } catch (err) {
        setError(err.message); // Set error if fetch fails
        setLoading(false); // Stop loading if an error occurs
      }
    };

    fetchBlogs();
  }, []); // Empty dependency array to run once when the component mounts

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/Blog" },
          { label: "Customers", path: "/apps/Blog", active: true },
        ]}
        title={"Blog"}
      />
      
      {/* Blog Search and Add Blog Button */}
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-center">
            <Col sm={3}>
              <Link to="/tables/AddBlog" className="btn btn-primary">
                <i className="uil uil-plus me-1"></i> Add Blog
              </Link>
            </Col>
            <Col sm={9}>
              <div className="float-sm-end mt-3 mt-sm-0">
                <div className="task-search d-inline-block me-sm-1 mb-3">
                  <form>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control search-input"
                        placeholder="Search Blog..."
                        // You can implement search functionality here
                      />
                      <Button
                        variant="primary"
                        className="input-group-text"
                        type="button"
                      >
                        <i className="uil uil-search"></i>
                      </Button>
                    </div>
                  </form>
                </div>
                <Dropdown className="d-inline-block" align="end">
                  <Dropdown.Toggle variant="secondary" className="cursor-pointer">
                    <i className="uil uil-sort-amount-down"></i> Sort By
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Date</Dropdown.Item>
                    <Dropdown.Item>Title</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Blog Posts Display */}
      <Card>
        <Card.Body>
          {loading ? (
            <p>Loading blogs...</p> // Display loading state
          ) : error ? (
            <p>Error fetching blogs: {error}</p> // Display error if fetch fails
          ) : (
            <div className="row">
              {blogs?.map((post) => (
                <div className="col-md-6 col-lg-4 mb-4" key={post.blogId}>
                  <Card className="h-100">
                    <Card.Img variant="top" style={{height:'300px',objectFit:"cover"}} src={post.imageUrl} alt={post.heading} />
                    <Card.Body>
                      <Card.Title>{post.heading}</Card.Title>
                      <Card.Text>{post.content.slice(0, 100)}</Card.Text> {/* Display excerpt */}
                      <span className="text-primary">Read More..</span>
                    </Card.Body>
                    <Card.Footer>
                      <small className="text-muted">Published on {new Date(post.createdAt).toLocaleDateString()}</small>
                    </Card.Footer>
                  </Card>
                </div>
              ))}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default BlogPost;
