import React, { useState, useEffect } from "react";
import PageTitle from "../../../components/PageTitle";
import { Card, Form, Row, Col, Container, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS file for toastify
import VendorDetails from "./VendorDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

function ThemeContent() {
  // State for form fields
  const [companyName, setCompanyName] = useState("");
  const [companyDetails, setCompanyDetails] = useState("");
  const [companyLocation, setCompanyLocation] = useState("");
  const [branches, setBranches] = useState([""]);
  const [headquarters, setHeadquarters] = useState("");
  const [socialMedia, setSocialMedia] = useState([""]);
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [policy, setPolicy] = useState("");
  const [vendorData, setVendorData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [files, setFiles] = useState([]);
  const [vendorId, setVendorId] = useState(null);
  const [logo, setLogo] = useState(null);
  const tenantId = localStorage.getItem("TenantId");

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_FOS_CLIENT_API}/vendor?tenantName=${tenantId}`
        );
        const data = await response.json();
        if (data.length >= 1) {
          setVendorData(data[0]);
          setVendorId(data[0].id);
        }
      } catch (error) {
        console.error("Error fetching vendor data:", error);
      }
    };

    fetchVendorData();
  }, [tenantId]);

  const addBranch = () => {
    setBranches([...branches, ""]);
  };

  const removeBranch = (index) => {
    setBranches(branches.filter((_, i) => i !== index));
  };

  const handleBranchChange = (index, value) => {
    const updatedBranches = branches.map((branch, i) =>
      i === index ? value : branch
    );
    setBranches(updatedBranches);
  };

  const addSocialMedia = () => {
    setSocialMedia([...socialMedia, ""]);
  };

  const removeSocialMedia = (index) => {
    setSocialMedia(socialMedia.filter((_, i) => i !== index));
  };

  const handleSocialMediaChange = (index, value) => {
    const updatedSocialMedia = socialMedia.map((link, i) =>
      i === index ? value : link
    );
    setSocialMedia(updatedSocialMedia);
  };

  const handleImageUploadpost = async (files) => {
    const formData = new FormData();
    formData.append("files", files[0]);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_FOS_CLIENT_API}/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload images");
      }

      const result = await response.json();
      return result.urls[0];
    } catch (error) {
      toast.error("Image upload failed");
      console.log(error);
      throw error;
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles(selectedFiles);

    if (selectedFiles && selectedFiles.length > 0) {
      const previewUrl = URL.createObjectURL(selectedFiles[0]);
      setLogo(previewUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const imageUrls = await handleImageUploadpost(files);
    const data = {
      companyName,
      companyLogo: imageUrls,
      companyDetails,
      address: companyLocation,
      socialMedia,
      email,
      contact,
      policy,
      branch: {
        headquarters,
        branches,
      },
    };

    try {
      const response = await fetch(
        isEditMode
          ? `${process.env.REACT_APP_FOS_CLIENT_API}/vendor/${vendorId}?tenantName=${tenantId}`
          : `${process.env.REACT_APP_FOS_CLIENT_API}/vendor?tenantName=${tenantId}`,
        {
          method: isEditMode ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (response.ok) {
        toast.success(
          isEditMode
            ? "Data updated successfully!"
            : "Data submitted successfully!"
        );
        window.location.reload();
        if (!isEditMode) {
          setCompanyName("");
          setFiles([]);
          setCompanyDetails("");
          setCompanyLocation("");
          setBranches([""]);
          setHeadquarters("");
          setSocialMedia([""]);
          setEmail("");
          setContact("");
          setPolicy("");
        }
      } else {
        toast.error("Error submitting data");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while submitting data");
    }
  };

  const handleEdit = () => {
    if (vendorData) {
      setCompanyName(vendorData.companyName);
      setFiles([vendorData.companyLogo]);
      setCompanyDetails(vendorData.companyDetails);
      setCompanyLocation(vendorData.address);
      setBranches(vendorData.branch.branches || [""]);
      setHeadquarters(vendorData.branch.headquarters || "");
      setSocialMedia(vendorData.socialMedia || [""]);
      setEmail(vendorData.email);
      setContact(vendorData.contact);
      setPolicy(vendorData.policy);
      setLogo(vendorData.companyLogo);
      setIsEditMode(true);
    }
  };

  if (vendorData && !isEditMode) {
    return (
      <div>
        <VendorDetails vendorData={vendorData} />
        <div className="container">
          <button className="btn btn-outline-primary" onClick={handleEdit}>
            Edit
          </button>
        </div>
      </div>
    );
  }

  return (
    <Container fluid className="my-4">
      <PageTitle
        breadCrumbItems={[
          { label: "app", path: "/apps/PageContent" },
          { label: "Customers", path: "/apps/Orders", active: true },
        ]}
        title={"Add  Your  Store Details"}
      />

      <Card className="shadow-sm">
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={8} md={12} className="mb-4">
                <Form.Group className="mb-3">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="company"
                    placeholder="Enter your company"
                    value={companyName}
                    required
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Company Details</Form.Label>
                  <textarea
                    value={companyDetails}
                    className="form-control"
                    rows={4}
                    name="companyDetails"
                    placeholder="Enter company details"
                    onChange={(e) => {
                      setCompanyDetails(e.target.value);
                    }}
                    required
                  />
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>Company Location</Form.Label>
                  <textarea
                    className="form-control"
                    rows={4}
                    placeholder="Enter company location"
                    value={companyLocation}
                    name="companyLocation"
                 onChange={(e) => {
                      setCompanyLocation(e.target.value);
                    }}
                    required
                  />
                </Form.Group>

                {/* Branches Management */}
                <Form.Group className="mt-3">
                  <Form.Label>Branches</Form.Label>
                  {branches.map((branch, index) => (
                    <div key={index} className="d-flex mb-2">
                      <Form.Control
                        type="text"
                        name="branch"
                        placeholder="Enter branch location"
                        value={branch}
                        onChange={(e) =>
                          handleBranchChange(index, e.target.value)
                        }
                        className="me-2"
                      />
                      <Button
                        variant="danger"
                        onClick={() => removeBranch(index)}
                        disabled={branches.length === 1}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                  <Button variant="primary" onClick={addBranch}>
                    Add Branch
                  </Button>
                </Form.Group>

                {/* Social Media Management */}
                <Form.Group className="mt-3">
                  <Form.Label>Social Media Links</Form.Label>
                  {socialMedia.map((link, index) => (
                    <div key={index} className="d-flex mb-2">
                      <FontAwesomeIcon
                        icon={
                          index === 0
                            ? faFacebook
                            : index === 1
                            ? faTwitter
                            : index === 2
                            ? faInstagram
                            : index === 3
                            ? faYoutube
                            : faLinkedin
                        }
                        className="me-2"
                        style={{ fontSize: "1.5rem" }}
                      />
                      <Form.Control
                        type="link"
                        name="url"
                        placeholder="Enter social media link"
                        value={link}
                        onChange={(e) =>
                          handleSocialMediaChange(index, e.target.value)
                        }
                        className="me-2"
                      />
                      <Button
                        variant="danger"
                        onClick={() => removeSocialMedia(index)}
                        disabled={socialMedia.length === 1}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                  <Button
                    variant="primary"
                    onClick={addSocialMedia}
                    disabled={socialMedia.length >= 5} // Disable button if 5 links are present
                  >
                    Add Social Media
                  </Button>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>Headquarters</Form.Label>
                  <Form.Control
                    type="text"
                    name="headquarters"
                    placeholder="Enter headquarters location"
                    value={headquarters}
                    required
                    onChange={(e) => setHeadquarters(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                      name="phone"
                    placeholder="Enter your contact number"
                    value={contact}
                    required
                    onChange={(e) => setContact(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>Policy</Form.Label>
                  <textarea
                    value={policy}
                    name="policy"
                    onChange={(e) => {
                      setPolicy(e.target.value);
                    }}
                    className="form-control"
                    rows={4}
                    placeholder="Enter policy details"
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={4} md={12} className="mb-4">
                <Form.Group className="mb-3">
                  <Form.Label>Upload Logo</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  {logo && (
                    <img
                      src={logo}
                      alt="Logo Preview"
                      className="mt-2"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit" variant="primary">
              {isEditMode ? "Update" : "Submit"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <ToastContainer />
    </Container>
  );
}

export default ThemeContent;
