import React, { useEffect, useState } from 'react';
import { Card, List, Spin, Typography, Rate } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';


const { Title, Text } = Typography;

const SurveyAdmin = () => {
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch survey data from API
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_FOS_CLIENT_API}/survey`);
        const data = await response.json();
        setSurveys(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching survey data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // if (loading) {
  //   return <Spin tip="Loading feedback..." size="large" />;
  // }

  return (
    <div style={{ padding: '20px' }}>
      <Title level={2} style={{ textAlign: 'center', marginBottom: '30px' }}>
        Survey Feedback
      </Title>
      <List
        grid={{ gutter: 16, column: 3 }} // Display three cards per row
        dataSource={surveys}
        renderItem={(survey) => (
          <List.Item>
            <Card
              style={{
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
              title={<Title level={4} style={{ margin: 0 }}>{survey.name}</Title>}
              bordered={true}
            
            >
              <Rate 
                disabled 
                value={survey.rating / 2} // Assuming rating is out of 10, convert to 5 for Rate component
                style={{ fontSize: '20px', marginBottom: '10px' }} 
              />
              <p><Text strong>Rating:</Text> {survey.rating}/10</p>
              <p><Text strong>Comments:</Text> {survey.comments}</p>
              <p><Text strong>email:</Text> {survey.name}</p>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default SurveyAdmin;
